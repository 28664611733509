import { RefCallback, useCallback, useState, useSyncExternalStore } from 'react'

type Callback = () => void

type ObserverStore = {
  subscribe: (cb: Callback) => () => void
  getSnapshot: () => ResizeObserverEntry | undefined
  setTarget: (node: HTMLElement) => void
}

const mockObserverStore: ObserverStore = {
  subscribe: () => () => null,
  getSnapshot: () => undefined,
  setTarget: () => null,
}

const createObserverStore = (): ObserverStore => {
  if (typeof ResizeObserver === 'undefined') {
    return mockObserverStore
  }
  const callbacks = new Set<Callback>()
  let entry: ResizeObserverEntry | undefined
  const observer = new ResizeObserver(([e]) => {
    entry = e
    callbacks.forEach((cb) => cb())
  })
  const subscribe = (cb: Callback) => {
    callbacks.add(cb)
    return () => {
      observer.disconnect()
      callbacks.delete(cb)
    }
  }
  const getSnapshot = () => {
    return entry
  }
  const setTarget = (node: HTMLElement) => {
    observer.disconnect()
    observer.observe(node)
  }
  return { subscribe, getSnapshot, setTarget }
}

export const useResizeObserver = () => {
  const [observerStore] = useState(createObserverStore())
  const ref: RefCallback<HTMLElement> = useCallback(
    (node) => {
      if (!node) return
      observerStore.setTarget(node)
    },
    [observerStore]
  )
  const entry = useSyncExternalStore(
    observerStore.subscribe,
    observerStore.getSnapshot,
    observerStore.getSnapshot
  )
  return { ref, entry }
}
