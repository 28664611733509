import { Schemas } from '~/api/gen'
import { Img } from '~/components/elements/Img'
import { OpenInNewIcon } from '~/components/icons/generated/OpenInNewIcon'
import { Banner } from '~/features/shared/banner/types'

type NiauProps = {
  banners: (Schemas.banner & {
    positions: Schemas.banner_position[]
  })[]
  onClickBanner: (banner: Banner) => Promise<void>
}

export const Niau: React.FC<NiauProps> = ({ banners, onClickBanner }) => {
  return (
    <section className="flex flex-col gap-[13px]">
      <h2 className="text-[18px]">似合う</h2>
      <ul className="flex flex-col gap-[13px]">
        {banners.map((banner) => (
          <li key={banner.id} className="group">
            <button
              type="button"
              className="flex flex-col gap-[9px]"
              onClick={() => onClickBanner(banner)}
            >
              <Img
                src={banner.image_url}
                alt={banner.image_alt_attribute}
                width={185}
                height={82}
                className="h-[82px] w-[185px] group-hover:opacity-70"
              />
              <h3 className="text-left text-[13px] leading-[1.2] group-hover:underline">
                <span className="pr-[5px]">{banner.title}</span>
                <OpenInNewIcon className="inline h-3" />
              </h3>
              <p className="text-left text-[11px] leading-normal text-gray-600">
                {banner.sub_title}
              </p>
            </button>
          </li>
        ))}
      </ul>
    </section>
  )
}
