import {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

const DRAGGING_THRESHOLD = 10

// eslint-disable-next-line max-lines-per-function
export const useLoopCarouselImages = (
  imageCount: number,
  imagesContainerRef: RefObject<HTMLElement>
) => {
  /**
   * 画像の1枚の横幅
   */
  const [imageWidth, setImageWidth] = useState<number>(0)

  /**
   * 画像のスクロール位置
   */
  const imageScrollLeft = useRef<number>(0)

  /**
   * 複数枚あるかどうか
   */
  const hasSubImage = imageCount > 1

  /**
   * 画像の総数
   */
  const totalImageLength = imageCount - 1

  /**
   * ループ用の要素を含んだ場合のindexと、ループ用の要素を含まない場合のindex
   */
  const [internalCurrentImageIndex, setInternalCurrentImageIndex] = useState(0)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  /**
   * 最初の画像かどうか
   */
  const isFirstImage = useMemo(() => {
    return internalCurrentImageIndex == 0
  }, [internalCurrentImageIndex])

  /**
   * 最後の画像かどうか
   */
  const isLastImage = internalCurrentImageIndex === imageCount - 1

  /**
   * 画像の横幅を取得
   */
  const setImageWidthHandler = useCallback(() => {
    if (imagesContainerRef.current === null) return
    setImageWidth(imagesContainerRef.current.clientWidth)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * 現在のスクロール位置を取得
   */
  const setImagePosHandler = useCallback(() => {
    if (imagesContainerRef.current === null) return
    imageScrollLeft.current = imagesContainerRef.current.scrollLeft
    const index = Math.round(imageScrollLeft.current / imageWidth)
    setInternalCurrentImageIndex(index)
    setCurrentImageIndex(index >= totalImageLength ? 0 : index)
  }, [imageWidth, imagesContainerRef, totalImageLength])

  const onClickNext = useCallback(() => {
    if (imagesContainerRef.current === null) return
    imagesContainerRef.current.scrollTo({
      left: imageScrollLeft.current + imageWidth,
      behavior: 'smooth',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageWidth])

  const onClickPrev = useCallback(() => {
    if (imagesContainerRef.current === null) return
    imagesContainerRef.current.scrollTo({
      left: imageScrollLeft.current - imageWidth,
      behavior: 'smooth',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageWidth])

  const onClickFirst = useCallback(() => {
    if (imagesContainerRef.current === null) return
    imagesContainerRef.current.scrollTo({ left: 0, behavior: 'instant' })
    setImagePosHandler()
    onClickNext()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onClickNext, setImagePosHandler])

  const onClickLast = useCallback(() => {
    if (imagesContainerRef.current === null) return
    imagesContainerRef.current.scrollTo({
      left: imageWidth * (imageCount - 1),
    })
    setImagePosHandler()
    onClickPrev()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageWidth, imageCount, onClickPrev, setImagePosHandler])

  const scrollToIndex = useCallback(
    (index: number) => {
      if (imagesContainerRef.current === null) return
      if (currentImageIndex === index) return
      imagesContainerRef.current.scrollTo({
        left: imageWidth * index,
        behavior: 'smooth',
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageWidth, currentImageIndex]
  )

  /**
   *ドラッグでスクロールさせる用
   **/
  const mouseX = useRef(0)
  const startX = useRef(0)
  const isDown = useRef(false)
  const [isDragging, setIsDragging] = useState(false)
  const onDragMouseDown = useCallback((e: React.MouseEvent) => {
    e.preventDefault()
    if (imagesContainerRef.current === null) return
    isDown.current = true
    mouseX.current = e.pageX
    startX.current = imagesContainerRef.current.scrollLeft
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onDragMouseUp = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      if (imagesContainerRef.current === null) return
      isDown.current = false
      imagesContainerRef.current.scrollTo({
        left: imageWidth * internalCurrentImageIndex,
        behavior: 'smooth',
      })

      if (Math.abs(mouseX.current - e.pageX) > DRAGGING_THRESHOLD) {
        setIsDragging(true)
      } else {
        setIsDragging(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageWidth, internalCurrentImageIndex, setIsDragging]
  )

  const onDragMouseMove = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault()
      if (imagesContainerRef.current === null) return
      if (!isDown.current) return
      const x = startX.current + (mouseX.current - e.pageX)
      const maxX = imageWidth * totalImageLength

      // 最初の画像で左にスクロールした場合
      if (internalCurrentImageIndex === 0 && x < 0) {
        startX.current = maxX
        imagesContainerRef.current.scrollLeft = maxX
        return
      }

      // 最後の画像で右にスクロールした場合
      if (internalCurrentImageIndex === totalImageLength && x > maxX) {
        startX.current = 0
        imagesContainerRef.current.scrollLeft = 0
        return
      }

      imagesContainerRef.current.scrollLeft = x
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageWidth, internalCurrentImageIndex, totalImageLength]
  )

  useEffect(() => {
    if (!hasSubImage || imagesContainerRef.current === null) return
    setImageWidthHandler()

    window.addEventListener('resize', setImageWidthHandler)
    imagesContainerRef.current.addEventListener('scroll', setImagePosHandler)
    return () => {
      if (imagesContainerRef.current === null) return
      window.removeEventListener('resize', setImageWidthHandler)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      imagesContainerRef.current.removeEventListener(
        'scroll',
        setImagePosHandler
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSubImage, setImagePosHandler, setImageWidthHandler])

  return {
    hasSubImage,
    isFirstImage,
    isLastImage,
    currentImageIndex,
    internalCurrentImageIndex,
    totalImageLength,
    isDragging,
    onClickNext,
    onClickPrev,
    onClickFirst,
    onClickLast,
    scrollToIndex,
    onDragMouseDown,
    onDragMouseUp,
    onDragMouseMove,
  }
}
