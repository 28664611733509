import { SearchAction, WebSite, WithContext } from 'schema-dts'
import { NEXT_PUBLIC_WEAR2_WEB_URL } from '~/lib/env'
import { getLdImageObject } from '~/lib/jsonLd'

// HACK: `query-input` プロパティが型定義に含まれないので拡張
// ref. https://github.com/google/schema-dts/issues/114#issuecomment-660303590
type QueryAction = SearchAction & { 'query-input': string }
const QUERY_ACTION: QueryAction = {
  '@type': 'SearchAction',
  target: {
    '@type': 'EntryPoint',
    urlTemplate: `${NEXT_PUBLIC_WEAR2_WEB_URL}/coordinate/?searchword_flag=1&search_word={search_term_string}`,
  },
  'query-input': 'required name=search_term_string',
}

export const TOP_PAGE_JSON_LD: WithContext<WebSite> = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'ファッションコーディネート - WEAR',
  description:
    'ZOZOが運営する日本最大級のファッションコーディネートサイト「WEAR（ウェア）」。参考になるおしゃれな服装や、今すぐほしいトレンドアイテムがたくさん！1400万枚以上の中からコーデが探せます。気に入ったアイテムはそのまま購入も！',
  url: NEXT_PUBLIC_WEAR2_WEB_URL,
  inLanguage: 'ja',
  image: getLdImageObject('https://wear.jp/public/ogp.png', 1200, 630),
  potentialAction: QUERY_ACTION,
}
