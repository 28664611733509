import { ComponentProps } from 'react'
import { Schemas } from '~/api/gen'
import { Banner } from '~/features/shared/banner/types'
import { Gender } from '~/lib/gender'
import { Brands } from './Brands'
import { Niau } from './Niau'
import { Notice } from './Notice'
import { PopularCategories } from './PopularCategories'
import { Recruit } from './Recruit'
import { SearchContents } from './SearchContents'
import { TrendKeywords } from './TrendKeywords'
import { Users } from './Users'

type SideMenuBanner = {
  banners: (Schemas.banner & {
    positions: Schemas.banner_position[]
  })[]
  onClickBanner: (banner: Banner) => Promise<void>
}

type Props = {
  gender?: Gender
  members: ComponentProps<typeof Users>['members']
  brands: ComponentProps<typeof Brands>['brands']
  trendKeywords: ComponentProps<typeof TrendKeywords>['keywords']
  popularCategories: ComponentProps<typeof PopularCategories>['categories']
  notice: SideMenuBanner
  niau: SideMenuBanner
}
type DividerProps = {
  afterViewAllLink?: boolean
}

const Divider: React.FC<DividerProps> = ({ afterViewAllLink = false }) => (
  <div
    className={`w-full ${afterViewAllLink ? 'py-[21px]' : 'pb-[21px] pt-[31px]'}`}
  >
    <hr className="border-gray-300" />
  </div>
)

export const SideMenu: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col">
      <SearchContents />
      <Divider />
      <Users members={props.members} gender={props.gender} />
      <Divider afterViewAllLink />
      <Brands brands={props.brands} />
      <Divider afterViewAllLink />
      <TrendKeywords keywords={props.trendKeywords} />
      <Divider />
      <PopularCategories categories={props.popularCategories} />
      <Divider afterViewAllLink />
      {props.notice.banners.length !== 0 && (
        <>
          <Notice
            banners={props.notice.banners}
            onClickBanner={props.notice.onClickBanner}
          />
          <Divider />
        </>
      )}
      {props.niau.banners.length !== 0 && (
        <>
          <Niau
            banners={props.niau.banners}
            onClickBanner={props.niau.onClickBanner}
          />
          <Divider />
        </>
      )}
      <Recruit />
    </div>
  )
}
