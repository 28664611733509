type Props = Pick<
  React.ComponentProps<'svg'>,
  'id' | 'className' | 'style' | 'tabIndex' | 'fill' | 'width' | 'height' |
  // aria props
  'role' | 'aria-hidden' | 'aria-label' | 'aria-labelledby' | 'aria-live' | 'aria-atomic' | 'aria-busy' | 'aria-activedescendant' | 'aria-autocomplete' | 'aria-braillelabel' | 'aria-brailleroledescription' | 'aria-checked' | 'aria-colcount' | 'aria-colindex' | 'aria-colspan' | 'aria-colindextext' | 'aria-controls' | 'aria-current' | 'aria-describedby' | 'aria-details' | 'aria-disabled' | 'aria-description' | 'aria-errormessage' | 'aria-expanded' | 'aria-flowto' | 'aria-grabbed' | 'aria-dropeffect' | 'aria-haspopup' | 'aria-invalid' | 'aria-keyshortcuts' | 'aria-level' | 'aria-modal' | 'aria-multiline' | 'aria-multiselectable' | 'aria-orientation' | 'aria-owns' | 'aria-placeholder' | 'aria-posinset' | 'aria-pressed' | 'aria-readonly' | 'aria-relevant' | 'aria-required' | 'aria-roledescription' | 'aria-rowcount' | 'aria-rowindex' | 'aria-rowindextext' | 'aria-rowspan' | 'aria-selected' | 'aria-setsize' | 'aria-sort' | 'aria-valuemax' | 'aria-valuemin' | 'aria-valuenow' | 'aria-valuetext'
>

export const ChevronLeftIcon: React.FC<Props> = (props) => (
<svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
<path fillRule="evenodd" clipRule="evenodd" d="M15.0364 6.7636C15.3879 7.11508 15.3879 7.68492 15.0364 8.0364L10.6728 12.4L15.0364 16.7636C15.3879 17.1151 15.3879 17.6849 15.0364 18.0364C14.685 18.3879 14.1151 18.3879 13.7637 18.0364L8.76365 13.0364C8.41218 12.6849 8.41218 12.1151 8.76365 11.7636L13.7637 6.7636C14.1151 6.41213 14.685 6.41213 15.0364 6.7636Z" />
</svg>
)
