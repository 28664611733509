export const getItemImageAlt = (props: {
  brandName: string
  brandNameKana?: string
  itemName: string
  categoryName: string
}) => {
  const brand = props.brandNameKana
    ? `${props.brandName}（${props.brandNameKana}）`
    : props.brandName
  return `${brand}の「${props.itemName}（${props.categoryName}）」`
}
