import { ImageObject, QuantitativeValue, Offer, Brand } from 'schema-dts'
import { Gender } from '~/lib/gender'

export const getLdPixel = (px: number): QuantitativeValue => ({
  '@type': 'QuantitativeValue',
  value: px,
  unitCode: 'PIX',
})

export const getLdCentimeter = (cm: number): QuantitativeValue => {
  return {
    '@type': 'QuantitativeValue',
    value: cm,
    unitCode: 'CMT',
  }
}

export const getLdImageObject = (
  url: string,
  width: number,
  height: number
): ImageObject => ({
  '@type': 'ImageObject',
  url,
  width: getLdPixel(width),
  height: getLdPixel(height),
})

export const getLdOffer = (price: string, isInStock: boolean): Offer => {
  return {
    '@type': 'Offer',
    priceCurrency: 'JPY',
    price,
    availability: isInStock
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock',
  }
}

export const getLdBrand = (
  name: string,
  url?: string,
  description?: string
): Brand => {
  return {
    '@type': 'Brand',
    name,
    description,
    url,
  }
}

export const getLdGender = (gender: Gender) => {
  switch (gender) {
    case 'women':
      return 'https://schema.org/Female'
    case 'men':
      return 'https://schema.org/Male'
    default:
      return undefined
  }
}
