import { Img } from '~/components/elements/Img'
import { UserIcon } from '~/components/icons/generated/UserIcon'
import { buildUserRankingUrl } from '~/features/UserRanking/lib/buildUserRankingUrl'
import { MemberBadge } from '~/features/shared/components/MemberBadge'
import { Gender } from '~/lib/gender'

type UsersProps = {
  gender?: Gender
  members: {
    href: string
    nickname: string
    image: string
    followersCount: number
    is_wearista: boolean
    is_shop_clerk: boolean
    is_salon_clerk: boolean
    is_sponsored: boolean
    is_awarded: boolean
  }[]
}

export const Users: React.FC<UsersProps> = (props) => {
  return (
    <section className="flex flex-col gap-2">
      <h2 className="text-[18px]">ユーザー</h2>
      <ul className="flex flex-col gap-[18px]">
        {props.members.map((member) => (
          <li key={member.href}>
            <a href={member.href} className="flex gap-[7px] hover:opacity-70">
              <Img
                src={member.image}
                alt={`${member.nickname}のアイコン`}
                className="border-gray size-[37px] shrink-0 overflow-hidden rounded-full border"
                width={40}
                height={40}
              />
              <div className="flex flex-col justify-center gap-[3px] overflow-hidden leading-none">
                <div className="flex items-center gap-[5px] text-[14px] font-bold">
                  <span className="truncate">{member.nickname}</span>
                  <MemberBadge
                    member={member}
                    className="block size-4 shrink-0"
                  />
                </div>
                <div className="flex gap-[2px]">
                  <UserIcon className="size-[11px]" />
                  <p className="text-[12px]">
                    {member.followersCount.toLocaleString()}
                  </p>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <a
        href={buildUserRankingUrl(props.gender ?? 'all')}
        className="w-full pr-[9px] pt-[5px] text-end text-[13px] font-bold text-blue-400 hover:underline"
      >
        すべて見る
      </a>
    </section>
  )
}
