import { BrandIcon } from '~/components/icons/generated/BrandIcon'
import { CoordinateIcon } from '~/components/icons/generated/CoordinateIcon'
import { ItemIcon } from '~/components/icons/generated/ItemIcon'
import { UserIcon } from '~/components/icons/generated/UserIcon'

const links = [
  { href: '/coordinate/', icon: CoordinateIcon, text: 'コーディネートを探す' },
  { href: '/user/', icon: UserIcon, text: 'ユーザーを探す' },
  { href: '/item/', icon: ItemIcon, text: 'アイテムを探す' },
  { href: '/brand/', icon: BrandIcon, text: 'ブランドを探す' },
]

export const SearchContents: React.FC = () => {
  return (
    <section className="flex flex-col gap-[6px]">
      <h2 className="text-[18px]">探す</h2>
      <ul className="flex flex-col gap-3">
        {links.map((link) => (
          <li
            key={link.text}
            className="flex items-center gap-[6px] text-[13px]"
          >
            <link.icon className="h-[19px]" />
            <a href={link.href} className="hover:underline">
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </section>
  )
}
