type TabItem<Tab> = {
  key: Tab
  title: string
  href?: string
}

type Props<Tab> = {
  items: TabItem<Tab>[]
  activeTab: Tab
}

export const ContentTabMenu = <Tab extends string>({
  items,
  activeTab,
}: Props<Tab>) => {
  return (
    <ul
      className="flex w-fit divide-x divide-gray-300 overflow-hidden rounded-[8px] border border-gray-300"
      role="tablist"
    >
      {items.map((item) => {
        const selected = item.key === activeTab
        return (
          <li key={item.title} role="presentation">
            <a
              href={item.href}
              role="tab"
              aria-selected={selected}
              tabIndex={selected ? -1 : 0}
              className="block w-[139px] bg-white py-[5px] text-center text-[13px] text-gray-500 hover:bg-gray-50 aria-selected:bg-black-400 aria-selected:text-white"
            >
              {item.title}
            </a>
          </li>
        )
      })}
    </ul>
  )
}
