import { ComponentProps } from 'react'
import { Schemas } from '~/api/gen'
import { Img } from '~/components/elements/Img'
import { RankingBadge } from '~/components/elements/RankingBadge'
import { BrandIcon } from '~/components/icons/generated/BrandIcon'
import { MemberBadge } from '~/features/shared/components/MemberBadge'
import { useResizeObserver } from '~/hooks/useResizeObserver'
import { CoordinateBalloon } from './CoordinateBalloon/CoordinateBalloon'

type Props = {
  href: string
  image: {
    url: string
    alt: string
  }
  imagePriority?: boolean
  member: {
    imageUrl: string
    userName: string
    nickName: string
    height?: number
  } & Pick<
    Schemas.member_small,
    | 'is_wearista'
    | 'is_shop_clerk'
    | 'is_salon_clerk'
    | 'is_sponsored'
    | 'is_awarded'
  >
  coordinateItem?: ComponentProps<typeof CoordinateBalloon>['coordinateItem']
  rank?: number
  onCoordinateItemVisibleToggleButtonClick: () => void
  onItemClick?: () => void
  hideCoordinateItem?: boolean
}

export const CoordinateCard: React.FC<Props> = ({
  href,
  image,
  imagePriority = false,
  member,
  coordinateItem,
  rank,
  onCoordinateItemVisibleToggleButtonClick,
  onItemClick,
  hideCoordinateItem = false,
}) => {
  const { ref, entry } = useResizeObserver()
  const imageWidth = entry?.contentRect?.width ?? 0
  const imageHeight = entry?.contentRect?.height ?? 0

  return (
    <div className="rounded-[2px] border border-gray-300 bg-white">
      <div className="relative" ref={ref}>
        {rank && (
          <div className="absolute z-10">
            <RankingBadge rank={rank} />
          </div>
        )}
        <a href={href} className="hover:opacity-70">
          <Img
            src={image.url}
            width="246"
            height="328"
            alt={image.alt}
            className="w-full rounded-t-[1px]"
            priority={imagePriority}
          />
        </a>
        {coordinateItem && (
          <>
            <CoordinateBalloon
              coordinateItem={coordinateItem}
              imageHeight={imageHeight}
              imageWidth={imageWidth}
              hidden={hideCoordinateItem}
              onClick={onItemClick}
            />
            <button
              aria-label={`コーディネートアイテムを${hideCoordinateItem ? '表示' : '非表示'}`}
              type="button"
              onClick={onCoordinateItemVisibleToggleButtonClick}
              className="absolute bottom-0 right-0 z-10 p-[7px] hover:opacity-80"
            >
              <span
                className={`flex size-7 items-center justify-center rounded-full ${hideCoordinateItem ? 'bg-black/50 text-white/70' : 'bg-white/80 text-black/60'}`}
              >
                <BrandIcon className="size-[15px]" />
              </span>
            </button>
          </>
        )}
      </div>
      <div className="flex gap-x-[10px] px-[10px] pb-[13px] pt-[11px]">
        <a
          href={`/${member.userName}/`}
          className="size-8 shrink-0 overflow-hidden rounded-full border border-gray-300 hover:opacity-70"
        >
          <Img
            src={member.imageUrl}
            width="32"
            height="32"
            alt={member.nickName}
          />
        </a>
        <div className="flex flex-col gap-y-[3px] truncate pt-[1px] leading-none tracking-wide">
          <a
            href={`/${member.userName}/`}
            className="flex items-center gap-x-1 text-[13px] font-bold hover:opacity-70"
          >
            <span className="truncate">{member.nickName}</span>
            <MemberBadge className="size-4 shrink-0" member={member} />
          </a>
          {member.height && <p className="text-[11px]">{member.height}cm</p>}
        </div>
      </div>
    </div>
  )
}
