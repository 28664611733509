import { useMemo } from 'react'
import { Img } from '~/components/elements/Img'
import { CartIcon } from '~/components/icons/generated/CartIcon'
import { getItemImageAlt } from '~/features/shared/item/lib/getItemImageAlt'
import { getBalloonPosition } from './lib/getBalloonPosition'

type Props = {
  coordinateItem: {
    id: number
    image_offset_point_x: number
    image_offset_point_y: number
    image: { url_125: string }
    item: {
      id: number | null
      name: string
      brand: { name: string; nameKana: string | null }
      display_price: string
      item_category: { name: string }
      item_child_category: { name: string } | null
    }
  }
  imageWidth: number
  imageHeight: number
  hidden?: boolean
  onClick?: () => void
}

const convertOriginClassName = (
  isRightSide: boolean,
  isDownwardTriangle: boolean
) => {
  if (isDownwardTriangle)
    return isRightSide ? 'origin-[90%_110%]' : 'origin-[10%_110%]'
  return isRightSide ? 'origin-[90%_-10%]' : 'origin-[10%_-10%]'
}

export const CoordinateBalloon: React.FC<Props> = ({
  coordinateItem,
  imageWidth,
  imageHeight,
  hidden = false,
  onClick,
}) => {
  // 吹き出しをクリックした時の遷移先
  const itemUrl = `/item/${coordinateItem.item.id}/`

  const balloonStyle = useMemo(
    () =>
      getBalloonPosition({
        imageOffsetPointX: coordinateItem.image_offset_point_x ?? 0.5,
        imageOffsetPointY: coordinateItem.image_offset_point_y ?? 0.5,
        imageWidth: imageWidth,
        imageHeight: imageHeight,
        balloonWidth: 159,
        balloonHeight: 58,
        balloonTriangleWidth: 6,
        balloonTriangleHeight: 4,
      }),
    [
      coordinateItem.image_offset_point_x,
      coordinateItem.image_offset_point_y,
      imageHeight,
      imageWidth,
    ]
  )

  return (
    <div
      className={`absolute flex w-[160px] transition duration-300 ease-coordinateTag aria-hidden:scale-0 aria-hidden:opacity-0 ${convertOriginClassName(balloonStyle.isRightSide, balloonStyle.isDownwardTriangle)}`}
      aria-hidden={hidden}
      style={{
        top: `${balloonStyle.balloonPositionY}px`,
        left: `${balloonStyle.balloonPositionX}px`,
      }}
    >
      <div
        className={`absolute size-[9px] rotate-45 bg-white ${balloonStyle.isDownwardTriangle ? '-bottom-1 ' : '-top-1'}`}
        style={{
          left: `${balloonStyle.trianglePositionX}px`,
        }}
      />
      <div
        className={`z-10 flex items-center rounded-[4px] bg-white ${balloonStyle.isRightSide ? 'flex-row-reverse' : ''}`}
      >
        <div
          className={`flex gap-2 p-[5px] ${balloonStyle.isRightSide ? 'flex-row-reverse pl-2' : 'pr-2'}`}
        >
          <a href={itemUrl} className="hover:opacity-70" onClick={onClick}>
            <Img
              src={coordinateItem.image.url_125}
              width={40}
              height={46}
              alt={getItemImageAlt({
                brandName: coordinateItem.item.brand.name,
                brandNameKana: coordinateItem.item.brand.nameKana || undefined,
                itemName: coordinateItem.item.name,
                categoryName: coordinateItem.item.item_category.name,
              })}
            />
          </a>
          <div className="flex w-[65px] flex-col justify-center gap-1">
            <p
              className="line-clamp-2 pt-[2px] text-[10px] leading-[14px]"
              aria-hidden
            >
              {coordinateItem.item.brand.name}
            </p>
            {coordinateItem.item.display_price && (
              <p className="text-[12px] font-bold leading-tight">
                {coordinateItem.item.display_price}
              </p>
            )}
          </div>
        </div>
        <a
          className={`flex h-full w-[33px] items-center justify-center bg-blue-400 hover:opacity-80 ${balloonStyle.isRightSide ? 'rounded-l-[4px]' : 'rounded-r-[4px]'}`}
          href={itemUrl}
          aria-hidden
          tabIndex={-1}
          onClick={onClick}
        >
          <CartIcon className="h-4 text-white" />
        </a>
      </div>
    </div>
  )
}
