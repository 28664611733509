import { getBalloonPositionX } from './getBalloonPositionX'

type Balloon = {
  /** 画像内の吹き出しの指す位置X (0.0~1.0) */
  imageOffsetPointX: number
  /** 画像内の吹き出しの指す位置Y (0.0~1.0) */
  imageOffsetPointY: number
  /** 画像の横幅 (px) */
  imageWidth: number
  /** 画像の縦幅 (px)*/
  imageHeight: number
  /** 吹き出しの横幅 (px) */
  balloonWidth: number
  /** 吹き出しの縦幅 (px) */
  balloonHeight: number
  /** 吹き出し矢印の三角形の横幅 (px) */
  balloonTriangleWidth: number
  /** 吹き出し矢印の三角形の縦幅 (px) */
  balloonTriangleHeight: number
}

export const getBalloonPosition = ({
  imageOffsetPointX,
  imageOffsetPointY,
  imageWidth,
  imageHeight,
  balloonWidth,
  balloonHeight,
  balloonTriangleWidth,
  balloonTriangleHeight,
}: Balloon): {
  isRightSide: boolean
  isDownwardTriangle: boolean
  /** 画像左上を始点とした吹き出しの位置X (px) */
  balloonPositionX: number
  /** 画像左上を始点とした吹き出しの位置Y (px) */
  balloonPositionY: number
  /** 吹き出し矩形の左上を始点とした吹き出し矢印の位置X (px) */
  trianglePositionX: number
} => {
  // 吹き出しの矢印が右にあるか
  const isRightSide = imageOffsetPointX > 0.5

  // 画像左上からの吹き出し矢印が指す相対位置
  const relativePositionX = imageWidth * imageOffsetPointX
  const relativePositionY = imageHeight * imageOffsetPointY

  // 吹き出し矢印が下向きか
  const isDownwardTriangle =
    relativePositionY + balloonTriangleHeight + balloonHeight > imageHeight

  const balloonPositionX = getBalloonPositionX({
    isRightSide,
    relativePositionX,
    balloonWidth,
    imageWidth,
  })

  // 矢印を考慮して吹き出しの位置を決定
  const balloonPositionY = isDownwardTriangle
    ? relativePositionY - balloonHeight - balloonTriangleHeight
    : relativePositionY + balloonTriangleHeight

  // 矢印の位置は吹き出しの左端を基準にした相対位置
  const trianglePositionX =
    relativePositionX - balloonTriangleWidth / 2 - balloonPositionX

  return {
    isRightSide,
    isDownwardTriangle,
    balloonPositionX,
    balloonPositionY,
    trianglePositionX,
  }
}
