type Props = Pick<
  React.ComponentProps<'svg'>,
  'id' | 'className' | 'style' | 'tabIndex' | 'fill' | 'width' | 'height' |
  // aria props
  'role' | 'aria-hidden' | 'aria-label' | 'aria-labelledby' | 'aria-live' | 'aria-atomic' | 'aria-busy' | 'aria-activedescendant' | 'aria-autocomplete' | 'aria-braillelabel' | 'aria-brailleroledescription' | 'aria-checked' | 'aria-colcount' | 'aria-colindex' | 'aria-colspan' | 'aria-colindextext' | 'aria-controls' | 'aria-current' | 'aria-describedby' | 'aria-details' | 'aria-disabled' | 'aria-description' | 'aria-errormessage' | 'aria-expanded' | 'aria-flowto' | 'aria-grabbed' | 'aria-dropeffect' | 'aria-haspopup' | 'aria-invalid' | 'aria-keyshortcuts' | 'aria-level' | 'aria-modal' | 'aria-multiline' | 'aria-multiselectable' | 'aria-orientation' | 'aria-owns' | 'aria-placeholder' | 'aria-posinset' | 'aria-pressed' | 'aria-readonly' | 'aria-relevant' | 'aria-required' | 'aria-roledescription' | 'aria-rowcount' | 'aria-rowindex' | 'aria-rowindextext' | 'aria-rowspan' | 'aria-selected' | 'aria-setsize' | 'aria-sort' | 'aria-valuemax' | 'aria-valuemin' | 'aria-valuenow' | 'aria-valuetext'
>

export const OpenInNewIcon: React.FC<Props> = (props) => (
<svg viewBox="0 0 1024 1024" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M832.635 472.351c-16.097 0-29.164 13.066-29.164 29.164v281.764c0 53.658-43.5 97.157-97.157 97.157h-466.371c-53.658 0-97.157-43.5-97.157-97.157v-466.412c0-53.658 43.5-97.157 97.157-97.157h272.056c16.097 0 29.164-13.066 29.164-29.164s-13.066-29.164-29.164-29.164h-272.056c-85.852 0-155.443 69.591-155.443 155.443v466.371c0 85.852 69.591 155.443 155.443 155.443h466.371c85.852 0 155.443-69.591 155.443-155.443v-281.764c0-16.056-13.025-29.082-29.123-29.082z" />
<path d="M910.377 85.279h-155.443c-16.097 0-29.164 13.025-29.164 29.164 0 8.069 3.236 15.319 8.52 20.603s12.575 8.52 20.603 8.52h85.074l-369.132 369.132c-11.387 11.387-11.387 29.819 0 41.206s29.819 11.387 41.206 0l369.132-369.132v85.074c0 8.069 3.236 15.319 8.52 20.603s12.575 8.52 20.603 8.52c16.097 0 29.164-13.025 29.164-29.164v-155.443c0.041-16.015-12.984-29.082-29.082-29.082z" />
</svg>
)
