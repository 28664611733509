import { ReactElement } from 'react'

type Props = {
  title: string
  subtitle?: string
  href?: string
  children: ReactElement
}

export const SectionContainer: React.FC<Props> = ({
  title,
  subtitle,
  href,
  children,
}) => {
  return (
    <section className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <h2 className="text-[18px]">{title}</h2>
          {subtitle && (
            <p className="pt-[2px] text-[15px] text-gray-600">{subtitle}</p>
          )}
        </div>
        {href && (
          <a
            href={href}
            className="mt-[5px] text-[13px] font-semibold text-blue-400 hover:underline"
          >
            すべて見る
          </a>
        )}
      </div>
      <div>{children}</div>
    </section>
  )
}
