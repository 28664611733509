import { Img } from '~/components/elements/Img'

type Props = {
  imgUrls: string[]
  href: string
  title: string
}

export const PopularTopicCard: React.FC<Props> = ({ imgUrls, href, title }) => {
  return (
    <a
      href={href}
      className="block h-[284px] w-[248px] overflow-hidden rounded-[3px] border bg-white"
    >
      <div className="grid aspect-[19/17] grid-cols-3 grid-rows-2 gap-[1px]">
        {imgUrls.slice(0, 6).map((url) => (
          <Img
            src={url}
            alt=""
            width={81}
            height={109}
            key={url}
            className="size-full object-cover"
          />
        ))}
      </div>
      <div className="overflow-hidden px-4 pb-3 pt-[13px]">
        <h3 className="line-clamp-2 text-[13px] font-bold leading-[1.4]">
          {title}
        </h3>
      </div>
    </a>
  )
}
