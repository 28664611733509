export const getCoordinateImageAltBrand = (
  brandName?: string,
  brandKana?: string
) => {
  if (!brandName) return undefined
  if (!brandKana) return brandName
  return `${brandName} | ${brandKana}`
}

export const getCoordinateImageAlt = ({
  nickName,
  brandName,
  brandNameKana,
  itemName,
}: {
  nickName: string
  brandName?: string
  brandNameKana?: string
  itemName?: string
}) => {
  const brand = getCoordinateImageAltBrand(brandName, brandNameKana)
  if (brand && itemName) {
    return `${nickName}さんの「${itemName}（${brand}）」を使ったコーディネート`
  }
  if (brand && !itemName) {
    return `${nickName}さんの（${brand}）を使ったコーディネート`
  }
  if (!brand && itemName) {
    return `${nickName}さんの「${itemName}」を使ったコーディネート`
  }
  return `${nickName}さんのコーディネート`
}
