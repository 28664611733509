import { Period } from '~/features/shared/ranking/types'
import { getGenderPathPrefix } from '~/features/shared/search/utils/getGenderPathPrefix'
import { Gender } from '~/lib/gender'

export const buildUserRankingUrl = (gender: Gender, period?: Period) => {
  const genderPrefix = getGenderPathPrefix(gender)
  const path = `/${genderPrefix}ranking/user/`
  if (period === undefined) return path

  return `${path}?date=${period.year}${period.month.toString().padStart(2, '0')}`
}
