// 矢印と吹き出しの余白
const balloonPadding = 16

type Balloon = {
  isRightSide: boolean
  relativePositionX: number
  balloonWidth: number
  imageWidth: number
}

export const getBalloonPositionX = ({
  isRightSide,
  relativePositionX,
  balloonWidth,
  imageWidth,
}: Balloon) => {
  // 矢印が右にあり吹き出し画像の左端をはみ出す場合
  if (isRightSide && relativePositionX - balloonWidth + balloonPadding < 0)
    return 0
  // 矢印が左にあり吹き出しが画像の右端をはみ出す場合
  if (
    !isRightSide &&
    relativePositionX + balloonWidth - balloonPadding > imageWidth
  )
    return imageWidth - balloonWidth
  // それ以外の場合
  return isRightSide
    ? relativePositionX - balloonWidth + balloonPadding
    : relativePositionX - balloonPadding
}
