type BrandsProps = {
  brands: {
    href: string
    brandName: string
    brandNameKana: string
  }[]
}

export const Brands: React.FC<BrandsProps> = ({ brands }) => {
  return (
    <section className="flex flex-col gap-[5px]">
      <h2 className="text-[18px]">ブランド</h2>
      <ul className="flex flex-col gap-[7px]">
        {brands.map((brand) => (
          <li key={brand.href} className="text-[13px]">
            <a
              href={brand.href}
              className="block max-w-full truncate hover:underline"
              aria-label={brand.brandNameKana}
            >
              {brand.brandName}
            </a>
          </li>
        ))}
      </ul>
      <a
        href="/brand/"
        className="w-full pr-[9px] pt-2 text-end text-[13px] font-bold text-blue-400 hover:underline"
      >
        すべて見る
      </a>
    </section>
  )
}
