import { Img } from '~/components/elements/Img'
import { imageBanners, links } from '~/features/shared/recruit/data'

export const Recruit: React.FC = () => {
  return (
    <section className="flex flex-col gap-[5px]">
      <h2 className="text-[18px]">採用情報</h2>
      <ul className="flex flex-col gap-[5px] pb-[3px] pt-[7px] empty:hidden">
        {imageBanners.map((imageBanner) => (
          <li key={imageBanner.href}>
            <a href={imageBanner.href} target="_blank" rel="noreferrer">
              <Img
                className="h-auto w-full"
                src={imageBanner.image.pc}
                alt={imageBanner.alt}
              />
            </a>
          </li>
        ))}
      </ul>
      <ul className="flex flex-col gap-[7px] pt-[3px]">
        {links.map((link) => (
          <li key={link.href} className="text-[13px]">
            <a
              href={link.href}
              className="block max-w-full truncate hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {link.name}
            </a>
          </li>
        ))}
      </ul>
      <a
        href="https://corp.zozo.com/recruit/mid-career/"
        target="_blank"
        rel="noreferrer"
        className="w-full pr-[9px] pt-2 text-end text-[13px] font-bold text-blue-400 hover:underline"
      >
        すべて見る
      </a>
    </section>
  )
}
