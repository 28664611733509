type PopularCategoriesProps = {
  categories: {
    href: string
    categoryName: string
  }[]
}

export const PopularCategories: React.FC<PopularCategoriesProps> = ({
  categories,
}) => {
  return (
    <section className="flex flex-col gap-[5px]">
      <h2 className="text-[18px]">人気のカテゴリー</h2>
      <ul className="flex flex-col gap-[7px]">
        {categories.map((category) => (
          <li key={category.href} className="text-[13px]">
            <a
              href={category.href}
              className="block max-w-full truncate hover:underline"
            >
              {category.categoryName}
            </a>
          </li>
        ))}
      </ul>
      <a
        href="/category/"
        className="w-full pr-[9px] pt-2 text-end text-[13px] font-bold text-blue-400 hover:underline"
      >
        すべて見る
      </a>
    </section>
  )
}
