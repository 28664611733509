type TrendKeywordsProps = {
  keywords: {
    href: string
    keyword: string
  }[]
}

export const TrendKeywords: React.FC<TrendKeywordsProps> = ({ keywords }) => {
  return (
    <section className="flex flex-col gap-[7px]">
      <h2 className="text-[18px]">急上昇ワード</h2>
      <ul className="flex flex-col gap-[7px]">
        {keywords.map((keyword) => (
          <li key={keyword.href} className="text-[13px]">
            <a
              href={keyword.href}
              className="block max-w-full truncate hover:underline"
            >
              {keyword.keyword}
            </a>
          </li>
        ))}
      </ul>
    </section>
  )
}
