import { Gender, genderToSexId } from '~/lib/gender'

const barWidths = {
  all: 'after:w-[89px]',
  men: 'after:w-[85px]',
  women: 'after:w-[115px]',
  kids: 'after:w-[87px]',
} as const

const getUnderBarClasses = (gender: Gender) => {
  return `after:absolute after:bottom-0 after:left-1/2 after:h-[3px] ${barWidths[gender]} after:-translate-x-1/2 after:bg-black-400 after:content-[""]`
}

type Props = {
  current?: Gender
}

const textClasses =
  'flex h-12 w-[178px] items-center justify-center text-[16px]'
export const GenderTabMenu: React.FC<Props> = ({ current = 'all' }) => {
  return (
    <nav className="flex justify-center bg-white">
      <ul className="flex w-[990px] justify-center">
        {Object.keys(Gender).map((key) => {
          const tabName = key.toUpperCase()
          return (
            <li key={key}>
              {current === key ? (
                <span
                  className={`${textClasses} relative text-black-400 ${getUnderBarClasses(key)}`}
                >
                  {tabName}
                </span>
              ) : (
                <a
                  href={`/?sex_id=${genderToSexId(key)}`}
                  className={`${textClasses} relative text-gray-500 ${getUnderBarClasses(key)} after:hidden after:hover:block`}
                >
                  {tabName}
                </a>
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}
