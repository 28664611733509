import { RankingBadgeIcon } from '~/components/icons/generated/RankingBadgeIcon'

type Props = {
  rank: number
  translucent?: boolean
}

const getRankBadgeColor = (rank: number) => {
  switch (rank) {
    case 1:
      return '#F8AF00'
    case 2:
      return '#848484'
    case 3:
      return '#B46800'
    default:
      return undefined
  }
}

export const RankingBadge: React.FC<Props> = ({ rank, translucent }) => {
  const rankColor = getRankBadgeColor(rank)
  const rankingBadgeClass =
    'flex justify-center text-[11px] xl:text-[13px] font-bold leading-none text-white'
  const ariaLabel = `順位 ${rank}位`

  if (rankColor === undefined) {
    return (
      <h4
        className={`size-5 items-center xl:size-7 ${translucent ? 'bg-black/20' : 'bg-gray-400'} ${rankingBadgeClass}`}
        aria-label={ariaLabel}
      >
        {rank}
      </h4>
    )
  }

  return (
    <h4 className="relative w-fit" aria-label={ariaLabel}>
      <span
        className={`absolute inset-0 pr-0 pt-1 xl:pr-[2px] xl:pt-[6px] ${rankingBadgeClass}`}
        aria-hidden
      >
        {rank}
      </span>
      <RankingBadgeIcon className="h-5 xl:h-7" fill={rankColor} aria-hidden />
    </h4>
  )
}
