import { ComponentProps } from 'react'
import { Schemas } from '~/api/gen'
import { getCoordinateImageAlt } from '~/features/shared/coordinate/lib/getCoordinateImageAlt'
import { CoordinateCard } from '../CoordinateCard'

type Props = {
  coordinate: Pick<Schemas.coordinate, 'id' | 'member' | 'image'>
  hideCoordinateItem: boolean
  onCoordinateItemVisibleToggleButtonClick: () => void
  coordinateItems: Schemas.coordinate_item_with_coordinate_id[]
  rank?: number
}

export const convertToCoordinateCardProps = ({
  coordinate,
  hideCoordinateItem,
  onCoordinateItemVisibleToggleButtonClick,
  coordinateItems,
  rank,
}: Props): ComponentProps<typeof CoordinateCard> => {
  const coordinateItem = coordinateItems.find(
    (item) => item.coordinate_id === coordinate.id
  )?.coordinate_item

  return {
    href: `/${coordinate.member.user_name}/${coordinate.id}/`,
    image: {
      url: coordinate.image.url_320,
      alt: getCoordinateImageAlt({
        nickName: coordinate.member.nick_name,
        brandName: coordinateItem?.brand?.name,
        brandNameKana: coordinateItem?.brand?.name_kana ?? undefined,
        itemName: coordinateItem?.name,
      }),
    },
    member: {
      ...coordinate.member,
      imageUrl: coordinate.member.image.url_80,
      userName: coordinate.member.user_name,
      nickName: coordinate.member.nick_name,
      height: coordinate.member.height ?? undefined,
    },
    rank: rank,
    coordinateItem:
      coordinateItem?.item &&
      coordinateItem.brand &&
      coordinateItem.item.display_price
        ? {
            id: coordinateItem.id,
            image_offset_point_x: coordinateItem.image_offset_point_x ?? 0.5,
            image_offset_point_y: coordinateItem.image_offset_point_y ?? 0.5,
            image: {
              url_125: coordinateItem.image.url_125,
            },
            item: {
              id: coordinateItem.item.id,
              name: coordinateItem.name,
              brand: {
                name: coordinateItem.brand.name,
                nameKana: coordinateItem.brand.name_kana,
              },
              display_price: coordinateItem.item.display_price,
              item_category: {
                name: coordinateItem.item_category?.name ?? '',
              },
              item_child_category: coordinateItem.item_child_category
                ? {
                    name: coordinateItem.item_child_category.name,
                  }
                : null,
            },
          }
        : undefined,
    hideCoordinateItem,
    onCoordinateItemVisibleToggleButtonClick,
  }
}
