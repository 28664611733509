import { useEffect, useRef, useState } from 'react'
import { useButton } from 'react-aria'
import { Img } from '~/components/elements/Img'
import { ChevronLeftIcon } from '~/components/icons/generated/ChevronLeftIcon'
import { ChevronRightIcon } from '~/components/icons/generated/ChevronRightIcon'
import { useAbstractUrl } from '~/features/shared/abstractUrl/hooks/useAbstractUrl'
import { useBanner } from '~/features/shared/banner/hooks/useBanner'
import { Banner } from '~/features/shared/banner/types'
import { useLoopCarouselImages } from '~/hooks/useLoopCarouselImages'

const AUTO_SCROLL_INTERVAL = 6000

export const ImageCountIndicator: React.FC<{
  current: number
  total: number
  onClick: (i: number) => void
}> = ({ current, total, onClick }) => (
  <div className="flex gap-x-4">
    {[...Array(total)].map((_, i) => (
      <button
        key={i}
        type="button"
        className={`size-[10px] rounded-full ${
          i === current ? 'bg-black-400' : 'bg-gray-400'
        } hover:bg-black-400`}
        onClick={() => onClick(i)}
        aria-label={`${i + 1}枚目のスライドに移動`}
      />
    ))}
  </div>
)

export const CarouselBannersView: React.FC<{
  banners: Banner[]
  onClickBanner: (banner: Banner) => Promise<void>
}> = ({ banners, onClickBanner }) => {
  const imagesContainerRef = useRef<HTMLUListElement>(null)

  //無限ループ用に最初のバナーを最後に追加
  const adjustBanners = (
    banners.length > 1 ? [...banners, banners[0]] : banners
  ).filter((banner) => banner !== undefined)

  const {
    hasSubImage,
    isFirstImage,
    isLastImage,
    currentImageIndex,
    internalCurrentImageIndex,
    totalImageLength,
    isDragging,
    onClickNext,
    onClickPrev,
    onClickFirst,
    onClickLast,
    scrollToIndex,
    onDragMouseDown,
    onDragMouseUp,
    onDragMouseMove,
  } = useLoopCarouselImages(adjustBanners.length, imagesContainerRef)

  const prevButtonRef = useRef<HTMLButtonElement>(null)
  const { buttonProps: prevButtonProps } = useButton(
    {
      onPress: isFirstImage ? onClickLast : onClickPrev,
      'aria-label': '前のスライドに移動',
    },
    prevButtonRef
  )

  const nextButtonRef = useRef<HTMLButtonElement>(null)
  const { buttonProps: nextButtonProps } = useButton(
    {
      onPress: isLastImage ? onClickFirst : onClickNext,
      'aria-label': '次のスライドに移動',
    },
    nextButtonRef
  )

  //自動スクロール
  const [isHover, setIsHover] = useState(false)
  useEffect(() => {
    if (banners.length === 1) return
    if (isHover) return
    const intervalId = setInterval(() => {
      if (internalCurrentImageIndex === totalImageLength) onClickFirst()
      else onClickNext()
    }, AUTO_SCROLL_INTERVAL)

    return () => clearInterval(intervalId)
  }, [
    onClickNext,
    onClickFirst,
    internalCurrentImageIndex,
    totalImageLength,
    isHover,
    banners,
  ])

  // フリック入力できないようにする
  // onWheelだと下記エラーが発生するためuseEffectで`passive:false`を指定
  // `Unable to preventDefault inside passive event listener invocation.`
  useEffect(() => {
    if (imagesContainerRef.current === null) return

    const imagesContainer = imagesContainerRef.current
    const preventScroll = (e: WheelEvent) => e.preventDefault()
    imagesContainer.addEventListener('wheel', preventScroll, { passive: false })

    return () => imagesContainer.removeEventListener('wheel', preventScroll)
  }, [])

  return (
    <div className="relative w-[465px]">
      <ul
        ref={imagesContainerRef}
        className="flex w-[465px] overflow-x-auto overflow-y-hidden hide-scroll"
        onMouseDown={onDragMouseDown}
        onMouseUp={onDragMouseUp}
        onMouseLeave={onDragMouseUp}
        onMouseMove={onDragMouseMove}
        role="listbox"
      >
        {adjustBanners.map((banner, index) => {
          return (
            <li
              key={banner.id}
              className="flex w-[465px] shrink-0 snap-center"
              role="option"
              aria-selected={internalCurrentImageIndex === index}
            >
              <button
                type="button"
                onClick={() => {
                  if (!isDragging) onClickBanner(banner)
                }}
                onMouseOver={() => setIsHover(true)}
                onMouseOut={() => setIsHover(false)}
                onFocus={() => setIsHover(true)}
                onBlur={() => setIsHover(false)}
                className="hover:opacity-70"
              >
                <Img
                  src={banner.image_url}
                  alt={banner.image_alt_attribute || banner.title}
                  width={345}
                  height={133}
                  className="w-full"
                  draggable="false"
                />
              </button>
            </li>
          )
        })}
      </ul>

      {hasSubImage && (
        <>
          <button
            className="absolute left-[-64px] top-[90px] size-10 -translate-y-1/2 text-black hover:opacity-40"
            type="button"
            {...prevButtonProps}
            ref={prevButtonRef}
          >
            <ChevronLeftIcon className="size-10" />
          </button>
          <button
            className="absolute right-[-64px] top-[90px] size-10 -translate-y-1/2 text-black hover:opacity-40"
            type="button"
            {...nextButtonProps}
            ref={nextButtonRef}
          >
            <ChevronRightIcon className="size-10" />
          </button>
          <div className="absolute bottom-[-21px] flex w-full justify-center">
            <ImageCountIndicator
              current={currentImageIndex}
              total={totalImageLength}
              onClick={scrollToIndex}
            />
          </div>
        </>
      )}
    </div>
  )
}

export const CarouselBanners: React.FC = () => {
  const abstractUrl = useAbstractUrl()
  const { banners, onClickBanner } = useBanner('pc_ftr_ad', abstractUrl)
  if (banners.length === 0) return null

  return <CarouselBannersView banners={banners} onClickBanner={onClickBanner} />
}
