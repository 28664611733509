import { Schemas } from '~/api/gen'
import { Banner } from '~/features/shared/banner/types'

type Props = {
  banners: (Schemas.banner & {
    positions: Schemas.banner_position[]
  })[]
  onClickBanner: (banner: Banner) => Promise<void>
}

export const Notice: React.FC<Props> = ({ banners, onClickBanner }) => {
  return (
    <section id="notice" className="flex scroll-mt-[75px] flex-col gap-[9px]">
      <h2 className="text-[18px]">お知らせ</h2>
      <ul className="flex flex-col gap-[10px]">
        {banners.map((banner) => {
          return (
            <li key={banner.id}>
              <button
                type="button"
                onClick={() => onClickBanner(banner)}
                className="text-left text-[13px] leading-[1.6] hover:underline"
              >
                {banner.title}
              </button>
            </li>
          )
        })}
      </ul>
    </section>
  )
}
